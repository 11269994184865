import "../scss/styles.scss";

const [w,d] = [window, document];

class init {
  constructor () {
    this.navDrawer('drawer');
  }

  navDrawer(name) {
    const btns = d.querySelectorAll(`[data-plugin="${name}"]`);
    btns.forEach(btn => {
      const targets = d.querySelectorAll(btn.dataset.target);
      btn.addEventListener('click', () => {
        btn.classList.toggle('is-open');
        targets.forEach(toggle);
      });
    });
    const toggle = target => {
      target.classList.toggle('is-open');
      d.body.classList.toggle('is-drawer-open');
      if( !d.body.classList.contains('is-drawer-open') ){
        btns.forEach(btn => btn.classList.remove('is-open'));
      }
    }
  }
}

d.addEventListener('DOMContentLoaded', new init );